import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ErrorComponent } from "../components/ErrorComponent";
import { Loading } from "../components/Loading";
import { loginRequest } from "../authConfig";
import UserList from "../components/UserList/UserList";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "../components/NavBar";
import HomeActions from "./HomeActions";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 2,
    paddingRight: 10,
  }
}));

export function Home() {
  const authRequest = {
    ...loginRequest
  };
  const classes = useStyles();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <CssBaseline />
      <Typography
        component="div"
        style={{
          backgroundColor: "#f6f9fb",
          height: "100vh",
          overflowY: "auto"
        }}
      >
        <NavBar />
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
            <Hidden only={['sm', 'xs']}>
              <UserList />
              </Hidden>
            </Grid>
            <Grid item xs={8}>
              <HomeActions />
            </Grid>
          </Grid>
        </div>
      </Typography>
    </MsalAuthenticationTemplate>
  );
}
