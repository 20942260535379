/*jshint node: true */
/*jshint esversion: 10 */
/*jshint multistr: true */

import React, {useEffect, useState, useContext} from "react";
import {editUser, getUser} from "../../util/API/userAPI";
import {useMsal, useAccount} from "@azure/msal-react";
import {requestAPI} from "../../authConfig";
import {roleOptions} from "../AddUser/DefaultData";
import UserContext from "../Context/UserContext";
import {getDistributors} from "../../util/API/getDistributorsAPI";
import ActionContext from "../Context/ActionContext";
import UserListContext from "../Context/UserListContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "react-select";
import {CardActions, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import {SnackbarContent} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RoleTooltip from "../RoleTooltip";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  roles: [],
  billto: "",
};
const initialBoolState = {
  firstError: false, // first name
  lastError: false, // last name
  emailError: false, // email
  roleError: false, // roles
  locationError: false, // location
};

const UserProfile = () => {
  const {instance, accounts, inProgress} = useMsal();
  const account = useAccount(accounts[0] || {});
  const {ID} = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState([]);
  const [error, setError] = useState({...initialBoolState});
  const [formData, setFormData] = useState({...initialState});
  const [billToArray, setBillToArray] = useState([]);
  const {state, dispatch} = useContext(UserListContext); // eslint-disable-line no-unused-vars
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [saveLoading, setSaveLoading] = useState(false); // eslint-disable-line no-unused-vars

  const [success, setSuccess] = useState(false); // eslint-disable-line no-unused-vars
  const [location, setLocation] = useState([]);
  const [roles, setRoles] = useState(roleOptions); // eslint-disable-line no-unused-vars
  const {action, setAction} = useContext(ActionContext); // eslint-disable-line no-unused-vars
  const [statusMessage, setStatusMessage] = useState(""); // eslint-disable-line no-unused-vars
  const [APIError, setAPIError] = useState(false); // eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false); // eslint-disable-line no-unused-vars

  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: "4rem",
      backgroundColor: "#FFFFFF",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    },
    cardStyle: {
      marginTop: "4.25rem",
      backgroundColor: "#f6f9fb",
      position: "relative",
    },
    rootCardContent: {
      backgroundColor: "#f6f9fb",
      padding: "0.25em",
      scrollY: "auto",
      borderLeft: 0,
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    cardStyleHeader: {
      height: 57,
      marginBottom: 1,
      borderBottom: "1px solid rgba(224, 224, 224, 1);",
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontWeight: "500",
      width: "100%",
      paddingLeft: "1em",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    },
    cardStyleActions: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: "500",
      padding: 0,
      height: 57,
    },
    footer: {
      marginRight: "0.75em",
      padding: "0.75em",
    },

    form: {
      "& > *": {
        margin: theme.spacing(3),
        color: "#3C4257",
        fontWeight: "500",
        textAlign: "left",
      },
    },
    labelStyle: {
      padding: "0 0 0.1em",
    },
    textFieldStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootButton: {
      textTransform: "capitalize",
    },
    resetButtonStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootSelect: {
      marginTop: "0.25em",
      marginBottom: "0.25em",
      textTransform: "capitalize",
    },
    outlinedInput: {
      "&$cssFocused": {
        borderWidth: "4px",
        borderColor: `rgb(102,153,204,0.50) !important`,
      },
    },
    cssFocused: {},
    backdrop: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(246, 249, 251, 1)",
    },
    backdropUpdating: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(246, 249, 251, 0.3)",
    },
    snackBar: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();
  const goBack = () => {
    setAction("ADD_USER");
  };
  const handleChange = (e) => {
    setFormData({...formData, [e.target.id]: e.target.value});
  };
  const handleBillToChange = (selectedBillToOption) => {
    setFormData({
      ...formData,
      billto: {
        selectedBillToOption,
      },
    });
  };
  const handleRolesChange = (selectedRolesOption) => {
    setFormData({
      ...formData,
      roles: {
        selectedRolesOption,
      },
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSave = (e) => {
    e.preventDefault();
    setSaveLoading(true);
    let {firstName, lastName, email, roles, billto} = formData;
    let {
      roles: {selectedRolesOption},
      billto: {selectedBillToOption},
    } = formData;

    if (firstName === "") {
      setError({
        ...initialBoolState,
        firstError: true,
      });
      setSaveLoading(false);
      return;
    }
    if (lastName === "") {
      setError({
        ...initialBoolState,
        lastError: true,
      });
      setSaveLoading(false);
      return;
    }
    if (email === "") {
      setError({
        ...initialBoolState,
        emailError: true,
      });
      setSaveLoading(false);
      return;
    }
    let validiate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    if (!validiate.test(email)) {
      setError({
        ...initialBoolState,
        emailError: true,
      });
      setSaveLoading(false);
      return;
    }

    if (roles.length === 0) {
      setError({
        ...initialBoolState,
        roleError: true,
      });
      setSaveLoading(false);
      return;
    }
    if (selectedRolesOption.length === 0) {
      setError({
        ...initialBoolState,
        roleError: true,
      });
      setSaveLoading(false);
      return;
    }
    if (billto.length === 0) {
      setError({
        ...initialBoolState,
        locationError: true,
      });
      setSaveLoading(false);
      return;
    }
    if (selectedBillToOption.length === 0) {
      setError({
        ...initialBoolState,
        locationError: true,
      });
      setSaveLoading(false);
      return;
    }

    // Clean data for payload
    let tmp = JSON.parse(JSON.stringify(formData));
    delete tmp.id;
    let tmp2 = Object.values(tmp);
    let tmpBillTo = Object.values(tmp2[4]);
    let tmpRoles = Object.values(tmp2[5]);
    let extractBillTo = tmpBillTo[0].map((option) => option.value).join(",");
    let extractRoles = tmpRoles[0].map((option) => option.value.toString());

    //clean formData to match payload
    edit({...tmp, billto: extractBillTo, roles: extractRoles});
  };
  const editUSER = async (ID, payLoad, accessToken) => {
    editUser(ID, payLoad, accessToken)
      .then(() => {
        payLoad.id = ID;
        dispatch({type: "CHANGE_USER_LIST", data: payLoad});
        setSaveLoading(false);
        setAction("ADD_USER");
      })
      .catch((error) => {
        setSaveLoading(false);
        setStatusMessage("User could not be Updated!");
      });
  };
  const edit = (payLoad) => {
    const data = payLoad;
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          editUSER(ID, data, response.accessToken);
        });
    }
  };
  const getUserProfile = (id) => {
    if (id !== "") {
      if (account && inProgress === "none") {
        instance
          .acquireTokenSilent({
            ...requestAPI,
            account: account,
          })
          .then((response) => {
            let promise = [];
            promise.push(
              getUser(id, response.accessToken).then((response) => {
                let {roles} = response.data;
                const selectedRolesOption = roles.flatMap((t) => [
                  {value: t, label: t === "fin" ? "finance" : t},
                ]);
                setFormData({
                  ...response.data,
                  roles: {
                    selectedRolesOption,
                  },
                });
              })
            );
            Promise.all(promise).then(() => {
              getDistributors(response.accessToken).then((response) =>
                setBillToArray(response.data)
              );
            });
          });

        setErrorMessage([
          ["* Required"],
          ["*Required"],
          ["* Invalid Email Address"],
          ["* Required"],
        ]);
      }
    }
  };

  useEffect(() => {
    let tmp2 = Object.values(billToArray);
    let locationOptions = tmp2.flatMap((t) => [
      {
        value: t.billto,
        label: `${t.name} (${t.billto})`,
      },
    ]);
    setLocation(locationOptions);
    let {billto} = formData;
    const formatBillTo = billto
      .toString()
      .split(",")
      .flatMap((t) => [
        {
          value: parseInt(t, 10),
          label: "",
        },
      ]);
    const selectedBillToOption = [];
    formatBillTo.filter((t) => {
      locationOptions.forEach((x) => {
        if (x.value === t.value) {
          selectedBillToOption.push(x);
        }
      });
    }); // eslint-disable-line array-callback-return
    setFormData({
      ...formData,
      billto: {selectedBillToOption},
    });
    setLoading(false);
  }, [billToArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("Error Log", error);
  }, [error]);

  useEffect(() => {
    console.log(ID);
    setLoading(true);
    getUserProfile(ID);
  }, [ID]); // eslint-disable-line  react-hooks/exhaustive-deps

  let {
    firstName,
    lastName,
    email,
    phone,
    roles: {selectedRolesOption},
    billto: {selectedBillToOption},
  } = formData;
  let {firstError, lastError, emailError, roleError, locationError} = error;
  return (
    <Card className={classes.cardStyle}>
      {loading ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="primary" size="5rem" />
        </Backdrop>
      ) : null}
      {saveLoading ? (
        <Backdrop className={classes.backdropUpdating} open={true}>
          <CircularProgress color="primary" size="5rem" />
        </Backdrop>
      ) : null}
      <div className={classes.cardStyleHeader}>
        <Button onClick={() => goBack()} className={classes.rootButton}>
          <ArrowBackIosIcon style={{fontSize: "24px"}} />
          <Typography
            style={{fontSize: "16px", fontWeight: "500", color: "#3C4257"}}
          >
            Edit User
          </Typography>
        </Button>
      </div>
      <CardContent className={classes.rootCardContent}>
        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="firstName">
                First Name
              </label>
            </span>
            <FormHelperText margin="dense" error>
              {firstError ? errorMessage[0] : ""}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="firstName"
              variant="outlined"
              margin="dense"
              value={firstName}
              error={firstError}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="lastName">
                Last Name
              </label>
            </div>
            <FormHelperText margin="dense" error>
              {lastError ? errorMessage[1] : ""}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="lastName"
              variant="outlined"
              margin="dense"
              value={lastName}
              error={lastError}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="email">
                Email
              </label>
            </div>
            <FormHelperText margin="dense" error>
              {emailError && errorMessage[2]}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="email"
              variant="outlined"
              value={email}
              error={emailError}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="phone">
                Phone Number
              </label>
            </div>
            <TextField
              className={classes.textFieldStyle}
              id="phone"
              variant="outlined"
              value={phone}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </div>
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="roles">
                Role
              </label>
              <RoleTooltip />
            </span>
            <FormHelperText margin="dense" error>
              {roleError && errorMessage[3]}
            </FormHelperText>
            <Select
              className={classes.rootSelect}
              options={roles}
              maxMenuHeight={100}
              menuPlacement="auto"
              value={selectedRolesOption ? selectedRolesOption : ""}
              onChange={handleRolesChange}
              isMulti={true}
            />
          </div>
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="billto">
                Location
              </label>
            </span>
            <FormHelperText margin="dense" error>
              {locationError && errorMessage[3]}
            </FormHelperText>
            <Select
              className={classes.rootSelect}
              options={location}
              maxMenuHeight={100}
              menuPlacement="auto"
              value={selectedBillToOption ? selectedBillToOption : ""}
              onChange={handleBillToChange}
              isMulti={true}
            />
          </div>
        </form>
      </CardContent>
      <CardActions className={classes.cardStyleActions}>
        <span className={classes.footer}>
          <span style={{padding: "0.25em"}}>
            <Button
              variant="contained"
              className={classes.rootButton}
              onClick={(e) => handleSave(e)}
              color="primary"
            >
              Save
            </Button>
          </span>
        </span>
      </CardActions>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{backgroundColor: "#fff", color: "#3C4257"}}
          message={statusMessage}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Card>
  );
};

export default UserProfile;
