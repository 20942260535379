import { get, defaultHeaders } from "./axiosClient";

// return list of users the admin is responsble for based on billTo
export const getBillToUsers = async accessToken => {
  let res = await get(
    window._env_.REACT_APP_API_ENDPOINT_BILLTO ,
    defaultHeaders(accessToken)
  );
  return res;
};
