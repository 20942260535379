import {get, post, patch, del, defaultHeaders} from "./axiosClient";

//Returns information about a user
export const getUser = async (user_id, accessToken) => {
  if (!user_id || user_id.length < 2 || typeof user_id === "object") {
    return {};
  }
  let url = window._env_.REACT_APP_API_ENDPOINT_USER + "/" + user_id;
  console.log("URL", url);
  let response = await get(url, defaultHeaders(accessToken));
  return response;
};

//Creates User
export const createUser = async (payLoad, accessToken) => {
  let response = await post(
    window._env_.REACT_APP_API_ENDPOINT_USER,
    payLoad,
    defaultHeaders(accessToken)
  );
  return response;
};

//Edits User
export const editUser = async (user_id, payLoad, accessToken) => {
  let url = window._env_.REACT_APP_API_ENDPOINT_USER + "/" + user_id;
  let response = await patch(url, payLoad, defaultHeaders(accessToken));
  return response;
};

//Delete User
export const deleteUser = async (user_id, accessToken) => {
  let url = window._env_.REACT_APP_API_ENDPOINT_USER + "/" + user_id;
  let response = await del(url, defaultHeaders(accessToken));
  return response;
};
