import React, {useContext} from "react";
import ActionContext from "../components/Context/ActionContext";
import Grid from "@material-ui/core/Grid";

import UserProfile from "../components/UserProfile/UserProfile";
import AddUser from "../components/AddUser/AddUser";
import AddMutipleUsers from "../components/AddUser/AddMuitpleUsers";

const HomeActions = () => {
  const {action, setAction} = useContext(ActionContext); // eslint-disable-line no-unused-vars

  if (action === "EDIT_PROFILE") {
    return (
      <>
        <Grid item xs={12} md={10} lg={9}>
          <UserProfile />
        </Grid>
      </>
    );
  }
  if (action === "ADD_USER") {
    return (
      <>
        <Grid item xs={12} md={10} lg={9}>
          <AddUser />
        </Grid>
      </>
    );
  }

  if (action === "ADD_MUTIPLE_USERS") {
    return (
      <>
        <AddMutipleUsers />
      </>
    );
  }
};

export default HomeActions;
