import { get, defaultHeaders } from "./axiosClient";

// return list of distributors the admin is responsble for based on billTo
export const getDistributors = async accessToken => {
  let res = await get(
    window._env_.REACT_APP_API_ENDPOINT_DISTRI,
    defaultHeaders(accessToken)
  );
  return res;
};
