import {useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import Avatar from "@material-ui/core/Avatar";

const WelcomeName = () => {
  const {accounts} = useMsal();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      let initials = accounts[0].username.charAt(0); // default to first char of email address
      let fullName = accounts[0].name.split(" ");
      if (fullName.length > 1) {
        initials = (
          fullName[1].charAt(0) + fullName[0].charAt(0)
        ).toUpperCase();
      }
      setName(initials);
    }
  }, [accounts]);

  if (name) {
    return <Avatar>{name}</Avatar>;
  } else {
    return null;
  }
};

export default WelcomeName;
