import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import WelcomeName from "./WelcomeName";
import {Link as RouterLink} from "react-router-dom";

const NavBar = () => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography>
          <Link component={RouterLink} to="/" color="inherit" variant="h6">
            Distributor User Administration
          </Link>
          <span> v {window._env_.REACT_APP_VERSION}</span>
        </Typography>
        <div style={{marginLeft: "auto"}}>
          <WelcomeName />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
