import React from "react";
import SearchBar from "material-ui-search-bar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: 57,
    boxShadow: 0,
    border: '1px solid rgba(224, 224, 224, 1)',
  }
});
export default function Search(props) {
  const classes = useStyles();

  return (
    <>
      <SearchBar
        className={classes.root}
        value={props.value}
        onChange={props.onChange}
        onCancelSearch={props.onCancelSearch}
        placeholder="Search..."
      />
    </>
  );
}
