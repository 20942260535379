/*jshint node: true */
/*jshint esversion: 10 */
/*jshint multistr: true */

import React, {useEffect, useState, useContext} from "react";
import {getDistributors} from "../../util/API/getDistributorsAPI";
import {useMsal, useAccount} from "@azure/msal-react";
import {requestAPI} from "../../authConfig";
import {makeStyles} from "@material-ui/core/styles";
import {createUser} from "../../util/API/userAPI";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
import Select from "react-select";
import {CardActions, Typography} from "@material-ui/core";
import ActionContext from "../Context/ActionContext";
import UserListContext from "../Context/UserListContext";
import {roleOptions} from "./DefaultData";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import {SnackbarContent} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RoleTooltip from "../RoleTooltip";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  roles: [],
  billto: "",
};
const initialBoolState = {
  firstError: false, // first name
  lastError: false, // last name
  emailError: false, // email
  roleError: false, // roles
  locationError: false, // location
};

let locationOptions = [];

const AddUser = () => {
  const {instance, accounts, inProgress} = useMsal();
  const account = useAccount(accounts[0] || {});
  const [errorMessage, setErrorMessage] = useState([]);
  const [error, setError] = useState({...initialBoolState});
  const [formData, setFormData] = useState({...initialState});
  const [billToArray, setBillToArray] = useState([]);
  const [location, setLocation] = useState();
  const [roles, setRoles] = useState(roleOptions); // eslint-disable-line no-unused-vars
  const {action, setAction} = useContext(ActionContext); // eslint-disable-line no-unused-vars
  const {state, dispatch} = useContext(UserListContext); // eslint-disable-line no-unused-vars
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [statusMessage, setStatusMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [APIError, setAPIError] = useState(false);

  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: "4rem",
      backgroundColor: "#FFFFFF",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    },
    cardStyle: {
      marginTop: "4.25rem",
      backgroundColor: "#f6f9fb",
      position: "relative",
    },
    rootCardContent: {
      backgroundColor: "#f6f9fb",
      padding: "0.25em",
      scrollY: "auto",
      borderLeft: 0,
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    cardStyleHeader: {
      height: 57,
      marginBottom: 1,
      borderBottom: "1px solid rgba(224, 224, 224, 1);",
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontWeight: "500",
      width: "100%",
      paddingLeft: "1em",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    },
    cardStyleActions: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: "500",
      padding: 0,
      height: 57,
    },
    footer: {
      marginRight: "0.75em",
      padding: "0.75em",
    },

    form: {
      "& > *": {
        margin: theme.spacing(3),
        color: "#3C4257",
        fontWeight: "500",
        textAlign: "left",
      },
    },
    labelStyle: {
      padding: "0 0 0.1em",
    },
    textFieldStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootButton: {
      textTransform: "capitalize",
    },
    resetButtonStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootSelect: {
      marginTop: "0.25em",
      marginBottom: "0.25em",
      textTransform: "capitalize",
    },
    cssFocused: {},
    backdrop: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(246, 249, 251, 0.3)",
    },
    snackBar: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.paper,
    },
    customTooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const goFoward = () => {
    setAction("ADD_MUTIPLE_USERS");
  };
  const handleChange = (e) => {
    setFormData({...formData, [e.target.id]: e.target.value});
  };
  const handleBillToChange = (selectedBillToOption) => {
    setFormData({
      ...formData,
      billto: {
        selectedBillToOption,
      },
    });
  };
  const handleRolesChange = (selectedRolesOption) => {
    setFormData({
      ...formData,
      roles: {
        selectedRolesOption,
      },
    });
  };
  const resetForm = () => {
    setFormData({...initialState});
    setError({...initialBoolState});
  };
  const handleSave = (e) => {
    e.preventDefault();

    let {firstName, lastName, email, roles, billto} = formData;
    let {
      roles: {selectedRolesOption},
      billto: {selectedBillToOption},
    } = formData;

    if (firstName === "") {
      setError({
        ...initialBoolState,
        firstError: true,
      });
      return;
    }
    if (lastName === "") {
      setError({
        ...initialBoolState,
        lastError: true,
      });
      return;
    }
    if (email === "") {
      setError({
        ...initialBoolState,
        emailError: true,
      });
      return;
    }
    let validiate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    if (!validiate.test(email)) {
      setError({
        ...initialBoolState,
        emailError: true,
      });
      return;
    }

    if (roles.length === 0) {
      setError({
        ...initialBoolState,
        roleError: true,
      });
      return;
    }
    if (selectedRolesOption.length === 0) {
      setError({
        ...initialBoolState,
        roleError: true,
      });
      return;
    }
    if (billto.length === 0) {
      setError({
        ...initialBoolState,
        locationError: true,
      });
      return;
    }
    if (selectedBillToOption.length === 0) {
      setError({
        ...initialBoolState,
        locationError: true,
      });
      return;
    }

    let tmp = Object.values(formData);
    let tmpBillTo = Object.values(tmp[5]);
    let tmpRoles = Object.values(tmp[4]);
    let extractBillTo = tmpBillTo[0].map((option) => option.value).join(",");
    let extractRoles = tmpRoles[0].map((option) => option.value.toString());

    //clean formData to match payload
    create({...formData, billto: extractBillTo, roles: extractRoles});
  };

  const create = async (payLoad) => {
    setLoading(true);
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then(async (response) => {
          try {
            const res = await createUser(payLoad, response.accessToken);
            setLoading(false);
            dispatch({type: "ADD_USER_LIST", data: res.data});
            setStatusMessage("User Added successfully");
            resetForm();
            setAPIError(false);
          } catch (e) {
            setAPIError(true);
            setLoading(false);
            console.error(`${e.name}: ${e.message}`);
            if (e.message.includes("409")) {
              setError({
                ...initialBoolState,
                emailError: true,
              });
              setStatusMessage(
                "Duplicate email address. User could not be Added"
              );
            } else {
              setStatusMessage(
                "Backend API error. User could not be Added. Please wait a moment and try again"
              );
            }
          }
        });
    }
  };

  useEffect(() => {
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          getDistributors(response.accessToken).then((response) =>
            setBillToArray(response.data)
          );
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
    setErrorMessage([
      ["* Required"],
      ["*Required"],
      ["* Invalid Email Address"],
      ["* Required"],
    ]);
  }, [account, inProgress, instance]);

  useEffect(() => {
    if (statusMessage.length) {
      setOpen(true);
    }
  }, [statusMessage]);

  /*
  useEffect(() => {
    console.log("AddUser Form Data", formData);
  }, [formData]);
  */

  useEffect(() => {
    let tmp2 = Object.values(billToArray);
    locationOptions = tmp2.flatMap((t) => [
      {
        value: t.billto,
        label: t.name + " (" + t.billto + ")",
      },
    ]);
    setLocation(locationOptions);
  }, [billToArray]);

  let {
    firstName,
    lastName,
    email,
    phone,
    roles: {selectedRolesOption},
    billto: {selectedBillToOption},
  } = formData;
  let {firstError, lastError, emailError, roleError, locationError} = error;
  return (
    <Card className={classes.cardStyle}>
      {loading ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="primary" size="5rem" />
        </Backdrop>
      ) : null}
      <div className={classes.cardStyleHeader}>
        <div>
          <Typography
            style={{fontSize: "16px", fontWeight: "500", color: "#3C4257"}}
          >
            Add User
          </Typography>
        </div>
        <div style={{marginLeft: "auto"}}>
          <Button className={classes.rootButton} onClick={() => goFoward()}>
            <Typography
              style={{fontSize: "14px", fontWeight: "500", color: "#3C4257"}}
            >
              Switch to Multi-Add
            </Typography>
          </Button>
        </div>
      </div>
      <CardContent className={classes.rootCardContent}>
        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="firstName">
                First Name
              </label>
            </span>
            <FormHelperText margin="dense" error>
              {firstError ? errorMessage[0] : ""}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="firstName"
              variant="outlined"
              margin="dense"
              value={firstName}
              error={firstError}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="lastName">
                Last Name
              </label>
            </div>
            <FormHelperText margin="dense" error>
              {lastError ? errorMessage[1] : ""}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="lastName"
              variant="outlined"
              margin="dense"
              value={lastName}
              error={lastError}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="email">
                Email
              </label>
            </div>
            <FormHelperText margin="dense" error>
              {emailError && errorMessage[2]}
            </FormHelperText>
            <TextField
              className={classes.textFieldStyle}
              id="email"
              variant="outlined"
              value={email}
              error={emailError}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </div>
          <div>
            <div>
              <label className={classes.labelStyle} htmlFor="phone">
                Phone Number
              </label>
            </div>
            <TextField
              className={classes.textFieldStyle}
              id="phone"
              variant="outlined"
              value={phone}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </div>
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="roles">
                Role
              </label>
              <RoleTooltip />
            </span>
            <FormHelperText margin="dense" error>
              {roleError && errorMessage[3]}
            </FormHelperText>
            <Select
              className={classes.rootSelect}
              options={roles}
              maxMenuHeight={165}
              menuPlacement="auto"
              value={selectedRolesOption ? selectedRolesOption : ""}
              onChange={handleRolesChange}
              isMulti={true}
            />
          </div>
          <div>
            <span>
              <label className={classes.labelStyle} htmlFor="billto">
                Location
              </label>
            </span>
            <FormHelperText margin="dense" error>
              {locationError && errorMessage[3]}
            </FormHelperText>
            <Select
              className={classes.rootSelect}
              options={location}
              maxMenuHeight={340}
              menuPlacement="top"
              value={selectedBillToOption ? selectedBillToOption : ""}
              onChange={handleBillToChange}
              isMulti={true}
            />
          </div>
        </form>
      </CardContent>
      <CardActions className={classes.cardStyleActions}>
        <span className={classes.footer}>
          <span style={{padding: "0.25em"}}>
            <Button
              variant="contained"
              className={classNames(
                classes.rootButton,
                classes.resetButtonStyle
              )}
              onClick={() => resetForm()}
            >
              Clear Form
            </Button>
          </span>
          <span style={{padding: "0.25em"}}>
            <Button
              variant="contained"
              className={classes.rootButton}
              onClick={(e) => handleSave(e)}
              color="primary"
            >
              Add User
            </Button>
          </span>
        </span>
      </CardActions>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{backgroundColor: "#fff", color: "#3C4257"}}
          message={statusMessage}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Card>
  );
};
export default AddUser;
