import axios from "axios";

const apiClient = axios.create({
  //baseURL: window._env_.REACT_APP_API_ENDPOINT,
  headers: new Headers()
});

const defaultHeaders = accessToken => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken
    }
  };
  return config;
};

const { delete: del, get, post, patch } = apiClient;
export { get, post, patch, del, defaultHeaders };
