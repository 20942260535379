import React, {useState} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
}));

const RoleTooltip = () => {
  const [open, setOpen] = useState(false); // eslint-disable-line no-unused-vars
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Tooltip
      classes={{tooltip: classes.customWidth}}
      title={
        <React.Fragment>
          <Typography fontSize="12px" color="inherit">
            Roles represent the job responsibilities of the user. A user may
            have multiple roles.
            <br />
            All roles share a common set of basic applications (Content Hub,
            Gateway, Product Catalog, Reports, etc.)
            <br />
            <ul>
              <li>Sales - adds WebSMIS for Item X-ref and account info</li>
              <li>Buyer - adds Gallo Connect for web ordering</li>
              <li>Chains - adds Chain Pricing</li>
              <li>
                Finance - adds Gallo Connect financials, Reimbursements and
                Finance reports
              </li>
              <li>Pricing - adds reports for a Pricing Analyst</li>
            </ul>
          </Typography>
        </React.Fragment>
      }
    >
      <IconButton
        size="small"
        aria-label="close tooltip"
        color="inherit"
        onClick={handleClose}
      >
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default RoleTooltip;
