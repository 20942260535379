import React, {useState, useReducer} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

// Material-UI imports
import {ThemeProvider} from "@material-ui/core/styles";
import {theme} from "./styles/theme";

// MSAL imports
import {MsalProvider} from "@azure/msal-react";
import {Home} from "./pages/Home";

import UserContext from "./components/Context/UserContext";
import ActionContext from "./components/Context/ActionContext";
import UserListContext from "./components/Context/UserListContext";

// Set up Initial State
const initialState = [];

function reducer(state, action) {
  switch (action.type) {
    case "USER_LIST":
      return action.data;
    case "ADD_USER_LIST":
      delete action.data.roles;
      delete action.data.billto;
      delete action.data.phone;
      let data = [action.data];
      const res = data.map((user) => ({
        displayname: user.lastName + "," + user.firstName,
        id: user.id,
        email: user.email,
      }));
      console.log("new state", res);
      return state.concat(res);

    case "CHANGE_USER_LIST":
      const chgUser = {
        displayname: action.data.lastName + "," + action.data.firstName,
        id: action.data.id,
        email: action.data.email,
      };
      const rowIdx = state.findIndex((ele) => ele.id === action.data.id);
      if (rowIdx > -1) {
        state[rowIdx] = chgUser;
      }
      console.log("change", rowIdx, chgUser);
      return state;
    case "REMOVE_USER_LIST":
      return action.data;
    default:
      return initialState;
  }
}

function App({pca}) {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={pca}>
          <Pages />
        </MsalProvider>
      </ThemeProvider>
    </Router>
  );
}

function Pages() {
  const [ID, setID] = useState("");
  const value = {ID, setID};
  const [action, setAction] = useState("ADD_USER");
  const [state, dispatch] = useReducer(reducer, initialState);
  const valueAction = {action, setAction};
  return (
    <ActionContext.Provider value={valueAction}>
      <UserContext.Provider value={value}>
        <UserListContext.Provider value={{state, dispatch}}>
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </UserListContext.Provider>
      </UserContext.Provider>
    </ActionContext.Provider>
  );
}

export default App;
